export const IS_PRODUCTION = 0;
export const BASE_URL =
  IS_PRODUCTION == 2
    ? "http://10.10.1.103:3002/api/v1/"
    : IS_PRODUCTION == 0
    ? "https://stage-api.triskel-cap.com/api/v1/admin/"
    : "https://sto.triskel-cap.com/api/v1/";
export const BASE_IMAGE_URL =
  IS_PRODUCTION == 0
    ? "https://stage-triskel.s3.ap-southeast-1.amazonaws.com"
    : "https://prod-triskel.s3.ap-northeast-2.amazonaws.com";
export const REACT_APP_DOMAIN_KEY = "2WjUF3XuPl11eLocbNoDc9ZmzvwtGrlM";
// export const BASE_URL = 'http://10.1.8.7:4500/api/v1/admin/'
/**************************************DASHBOARD APIs****************************************************/
// export const USERS_COUNT_API = "user/access/dashboard";
// export const USERS_CHART_API = "user_traffic_chart";
// export const CHECK_PERMISSIONS = "auth/access/check_permissions";
// /**************************************LOGIN APIs****************************************************/
// export const LOGIN_API = "auth/access/login";
// /**************************************CURRENCY APIs****************************************************/
// export const GET_CURRENCY_LIST = "fiats/get_currencies";
// export const REMOVE_CURRENCY_API = "fiats/remove_currency";
// export const ADD_CURRENCY_API = "fiats/add_currency";
// export const ADD_CURRENCY_UPDATE_API = "fiats/update_currency";
// export const SEARCH_CURRENCY_API = "fiats/search_currency";
// /**************************************CUSTOM TOKEN APIs****************************************************/
// export const GET_TOKENS_LIST = "token/get_tokens";
// export const REMOVE_TOKENS_API = "token/remove_tokens";
// export const ADD_TOKENS_API = "token/add_token";
// export const SEARCH_TOKENS_API = "token/search_token";
// /**************************************USER APIs****************************************************/
// export const GET_USER_LIST = "access/user_list";
// export const GET_USER_DETAILS_API = "access/user_details";
// export const GET_USER_DATA = "access/user_data";
// /**************************************LOGOUT API****************************************************/
// export const LOGOUT_API = "auth/logout";
// /**************************************PASSWORD API****************************************************/
// export const CHANGE_PASSWORD_API = "auth/access/change_password";
// /**************************************UPLOAD API****************************************************/
// export const FILE_UPLOAD_API = "fiats/file_upload";
// /**************************************GECKO APIs****************************************************/
// export const GECKO_SYMBOL_URL = "https://api.coingecko.com/api/v3/coins/list";
// /**************************************2FA APIs****************************************************/
// export const GOOGLE_2FA_STATUS = "auth/access/google_auth_status";
// export const ENABLE_DISABLE_2FA_STATUS =
//   "auth/access/google_auth_enabledisable";
// export const GOOGLE_AUTH_SECRET_KEY = "auth/access/google_auth_secretkey";
// export const GOOGLE_AUTH_VERIFY = "auth/access/google_2fa_verify";
// /**************************************ANNOUNCEMENT API****************************************************/
// export const SEARCH_USER_API = "announcements/notification_user_list";
// export const PUSH_ANNOUNCEMENT_API = "announcements/allNotifications";
// /**************************************FORGOT PASS APIs****************************************************/
// export const RESET_PASS_API = "auth/access/forgot_password";
// export const RESET_PASS_ADMIN_API = "auth/access/reset_password";
// /**************************************REFERRAL APIs****************************************************/
// export const REFERRAL_LIST_API = "access/referral/getReferralInfo";
/**************************************CARD APIs****************************************************/
// export const CARD_USER_LIST_API = "access/card_module/cards_users";
// export const USER_SHIPPING_DETAILS_API = "access/card_module/shipping_detials";
// export const USER_CARD_DETAIL_API = "access/card_module/cards_user_details";
// export const USER_CARD_HISTORY_API = "access/card_module/user_card_history";
// export const SUPPORTED_COIN_API = "access/card_module/card_supported_coins";
// export const UPDATE_STATUS_API = "access/card_module/update_coinstatus";
// export const UPDATE_FEE_API = "access/card_module/update_fees";
// export const HYPERCARD_BALANCE_API = "access/card_module/hc_balnace";
// export const LIMINAL_BALANCE_API = "access/card_module/lmnl_balance";
// export const DOWNLOAD_HISTORY = "access/card_module/card/download_csv";
/**************************************CONTACT US APIs****************************************************/
// export const QUERY_LIST_API = "access/contact_us/all_queries";
// export const USER_QUERY_LIST = "access/contact_us/user_query";
// export const RESPONSE_QUERY_API = "access/contact_us/response_to_query";
// export const DOWNLOAD_QUERIES_List = "access/contact_us/download_csv";
/************************************ REFERRAL APIs *****************************************************/
// export const REFERRAL_TYPE_API = "access/referral/user_levels";
// export const GET_REFERRALS_API = "access/referral/get_referrals";
// export const GET_REFERRAL_DETAIL_API = "access/referral/get_referral_details";
// export const GET_REWARD_HISTORY_API = "access/referral/reward_history";
// export const DOWNLOAD_REWARD_HISTORY =
//   "access/referral/reward_history/download_csv";
// export const UPDATE_USER_LEVEL = "access/referral/update_user_level";
// export const APPROVAL_REQUEST_LIST = "access/referral/approval_requests";
// export const UPDATE_REFERRAL_STATUS = "access/referral/update_approval_request";
// export const GET_CLAIM_REQUEST = "access/referral/claim_requests";
// export const UPDATE_CLAIM_STATUS = "access/referral/update_claim_status";
/************************************ Sub Admin APIs *****************************************************/
// export const MANU_LIST_API = "subadmin/get_all_menulists";
// export const ADD_ROLE_API = "subadmin/add_role";
// export const ROLE_LIST_API = "subadmin/all_roles_with_list";
// export const DELETE_ROLE = "subadmin/delete_role";
// export const EDIT_ROLE_API = "subadmin/edit_role";
// export const ROLE_LIST_FOR_DROPDOWN_API = "subadmin/all_roles";
// export const ROLE_DETAIL_BY_ROLE_API = "subadmin/role_details";
// export const ADD_ADMIN_API = "subadmin/add_new_admin";
// export const ADMIN_LIST_API = "subadmin/all_admins";
// export const DELETE_ADMIN_API = "subadmin/delete_admin_users";
// export const ADMIN_DETAIL_API = "subadmin/admin_access_details";
// export const UPDATE_ADMIN_DETAIL_API = "subadmin/update_admin_user_details";
// export const RESEND_PASSWORD_API = "subadmin/resend_email";
// export const ACTIVITY_LOG_API = "subadmin/activity_logs";
// export const ACTIVITY_TYPE_API = "subadmin/get_activity_heads";

/************************************ Agent Admin APIs *****************************************************/

export const GET_PROPOSAL_LIST = "agent/proposalList";
export const GET_SECONDARY_PROPOSAL_LIST = "agent/secondaryProposalListAgent";

export const GET_INVESTED_REQUESTS_LIST = "agent/invested_particular_proposal";
export const GET_SECONDARY_INVESTED_REQUESTS_LIST =
  "agent/investedParticularSecondaryProposal";

export const GET_INVESTED_APP_LIST = "agent/getaddressBookListing";
export const GET_SECONDARY_ADDRESSES_LIST =
  "agent/getSecondaryAddressBookListing";

export const INVESTED_REQUESTS_APPROVE_REJECT = "agent/update_aprrove_proposal";
export const INVESTED_APP_REQUESTS_APPROVE_REJECT =
  "agent/activeInactiveAddressBook";

export const AGENT_LOGIN = "sto/admin/auth/login";
export const CHANGE_PASSWORD_AGENT = "sto/admin/auth/change_password";

export const DOWNLOAD_INVESTED_PROPOSAL_CSV =
  "agent/invested_particular_proposal_download_csv";

export const DOWNLOAD_INVESTED_APP_PROPOSAL_CSV =
  "agent/get_address_bookListing_download_csv";
