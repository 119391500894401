import Web3 from "web3";
import CONTRACT_ABI from "./../../Components/Assets/ABI/ContractABI.json";
import { CONTRACT_ADDRESS } from "../../constants/constants";
import { toast } from "../../Components/Ui/Toast/Toast";
const callWeb3 = async () => {
  const { ethereum } = window;
  let web3ObjectCall;

  if (ethereum && ethereum.isMetaMask) {
    web3ObjectCall = new Web3(ethereum);
    console.log("web3ObjectCall", web3ObjectCall);
    const networkId = await web3ObjectCall.eth.net.getId();

    if (networkId !== 80002) {
      toast.error("Please switch MetaMask network to Testnet Amoy");
      throw new Error("Please switch MetaMask network to Testnet Amoy");
    }
    // if (networkId !== 137) {
    //   toast.error("Please switch MetaMask network to Polygon Mainnet");
    //   throw new Error("Please switch MetaMask network to Polygon Mainnet");
    // }
    window.ethereum.enable(); // Request account access if needed
    return web3ObjectCall;
  }
  if (window.web3) {
    web3ObjectCall = new Web3(window.web3.currentProvider);
    console.log("web3ObjectCall", web3ObjectCall);

    return web3ObjectCall;
  }

  toast.error("MetaMask not found. Please install it!");
  throw new Error("MetaMask is required.");
};

export const callContract = async () => {
  const web3Object = await callWeb3();
  const contractObject = await new web3Object.eth.Contract(
    CONTRACT_ABI,
    CONTRACT_ADDRESS
  );
  console.log(contractObject, "contractObject");
  return contractObject;
};

export const addressWhitelistSingle = async (data) => {
  console.log("data in contract", data);
  try {
    const web3Object = await callWeb3();
    const accounts = await web3Object.eth.getAccounts();
    // const gasPrice = await web3Object.eth.getGasPrice();
    const owner = accounts[0];
    if (owner == undefined) {
      toast.error("Please connect to metamask and try again!");
    }
    console.log("owner", owner);

    const contract = await callContract();

    const _user = data?.userAddress;
    const _projectId = data?.projectId;
    const _salt = Date.now() + Math.random().toString(36).substring(2);
    const _country = 91;
    console.log(
      "useraddress",
      _user,
      "projectid",
      _projectId,
      "salt",
      _salt,
      "country",
      _country
    );
    const gasEstimation = await contract.methods
      .deployIdentityBind(_user, _projectId, _salt, _country)
      .estimateGas({ from: owner });
    console.log("GASESTIMATIONDONE", gasEstimation);
    const result = await contract.methods
      .deployIdentityBind(_user, _projectId, _salt, _country)
      .send({
        from: owner,
        gas: gasEstimation, // Use the estimated gas value
      });
    console.log("Single Address whitelist For Proposal:", result);
    if (result.events) {
      console.log("Inside events");
      const { events } = result;
      console.log("EVENTS", events);

      if (events) {
        console.log("Inside events");

        // const userAddress = mintedEvent.returnValues.user;
        // const amount = mintedEvent.returnValues.amount;
        // const txHash = mintedEvent.returnValues.transactionHash;

        // console.log(
        //   "Minted token , userAddress && TxId:",
        //   amount,
        //   userAddress,
        //   txHash
        // );
        return true; // Return the valuesl
      } else {
        toast.error("event not found in transaction receipt");
        console.error("event not found in transaction receipt");
        return null;
      }
    } else {
      console.error("Events property not found in transaction receipt");
      return null;
    }
  } catch (error) {
    if (
      error?.message?.includes("execution reverted: address stored already")
    ) {
      toast.info("Address already whitelisted."); // Display specific error message
      return "alreadyWhitelisted";
    } else if (
      error?.message?.includes(
        "execution reverted: AgentRole: caller does not have the Agent role"
      )
    ) {
      toast.error("User do not have the Agent or Admin role!."); // Display specific error message
    } else {
      toast.error("Something went wrong."); // Default error message
    }
    console.error(
      "Error in addressWhitelist deployIdentityBind:",
      error.message
    );
  }
};

export const addressWhitelistBatch = async (data) => {
  console.log("data", data.data);

  try {
    const web3Object = await callWeb3();
    const accounts = await web3Object.eth.getAccounts();
    // const gasPrice = await web3Object.eth.getGasPrice();
    const owner = accounts[0];
    if (owner == undefined) {
      toast.error("Please connect to metamask and try again!");
    }
    console.log("owner", owner);

    const contract = await callContract();

    const _user = data?.data?.userAddresses;
    const _projectsId = data?.data?.project_id;
    const _salt = data?.data?.salts;
    const _countries = data?.data?.countries;

    console.log(
      "BEFORE GAS ESTIMATION",
      "_user",
      _user,
      "_projectsId",
      _projectsId,
      "salt",
      _salt,
      "country",
      _countries
    );
    const gasEstimation = await contract.methods
      .batchdeployIdentity(_user, _projectsId, _salt, _countries)
      .estimateGas({ from: owner });
    console.log(
      "_user",
      _user,
      "_projectsId",
      _projectsId,
      "salt",
      _salt,
      "country",
      _countries
    );
    const result = await contract.methods
      .batchdeployIdentity(_user, _projectsId, _salt, _countries)
      .send({
        from: owner,
        gas: gasEstimation,
      });
    console.log("Batch Addresses whitelist For Proposal:", result);
    if (result.events) {
      console.log("Inside events");
      const { events } = result;
      console.log("EVENTS", events);
      return true;
      //   const eventToBeCalled = events.AgentAdded;

      //   if (eventToBeCalled) {
      //     console.log("Inside eventToBeCalled");

      //     const proposalAgentAddress = eventToBeCalled.returnValues._agent;
      //     console.log("Deployed agentAddress :", proposalAgentAddress);
      //     return { proposalAgentAddress }; // Return the values
      //   } else {
      //     console.error("Deployed event not found  in transaction receipt");
      //     return null;
      //   }
    } else {
      console.error("Events property not found in transaction receipt");
      return null;
    }
  } catch (error) {
    if (
      error?.message?.includes(
        "execution reverted: AgentRole: caller does not have the Agent role"
      )
    ) {
      toast.error("User do not have the Agent or Admin role!."); // Display specific error message
    } else {
      toast.error("Something went wrong."); // Default error message
    }
    console.error(
      "Error in addressWhitelist batchdeployIdentity:",
      error.message
    );
  }
};

export const mintTokenForAddress = async (data) => {
  console.log("data", data);

  try {
    const web3Object = await callWeb3();
    const accounts = await web3Object.eth.getAccounts();
    // const gasPrice = await web3Object.eth.getGasPrice();
    const owner = accounts[0];
    if (owner == undefined) {
      toast.error("Please connect to metamask and try again!");
    }
    console.log("owner", owner);
    const tokenDecimals = 18;

    const contract = await callContract();

    const user = data?.userAddress;
    const token = data?.tokenAddress;
    const _amount = (data?.amount * Math.pow(10, tokenDecimals)).toString();
    console.log(
      "MINTING CONTRACT METHOD DATA, userAddress, tokenaddress, amount",
      user,
      token,
      _amount
    );
    const gasEstimation = await contract.methods
      .mintTokens(user, token, _amount)
      .estimateGas({ from: owner });

    const result = await contract.methods
      .mintTokens(user, token, _amount)
      .send({
        from: owner,
        gas: gasEstimation,
      });
    console.log("Result of Mint token for a user:", result);
    if (result.events) {
      console.log("Inside events");
      const { events } = result;
      console.log("EVENTS", events);
      const mintedEvent = events.tokenMint;

      if (mintedEvent) {
        console.log("Inside event deployed");

        const userAddress = mintedEvent.returnValues.user;
        const amount = mintedEvent.returnValues.amount;
        const txHash = mintedEvent.transactionHash;

        console.log(
          "Minted token amount, userAddress,txhash :",
          amount,
          userAddress,
          txHash
        );
        return { amount, userAddress, txHash }; // Return the valuesl
      } else {
        toast.error("tokenMint event not found in transaction receipt");
        console.error("tokenMint event not found in transaction receipt");
        return null;
      }
    } else {
      console.error("Events property not found in transaction receipt");
      return null;
    }
  } catch (error) {
    if (
      error?.message?.includes("execution reverted: Compliance not followed")
    ) {
      toast.error("Compliance not followed!."); // Display specific error message
    } else if (
      error?.message?.includes(
        "execution reverted: AgentRole: caller does not have the Agent role"
      )
    ) {
      toast.error("User do not have the Agent role!."); // Display specific error message
    } else {
      toast.error(error.message); // Default error message
    }
    console.error("Error while minting tokens:", error.message);
  }
};

export const batchMintingAll = async (data) => {
  console.log("data", data);

  try {
    const web3Object = await callWeb3();
    const accounts = await web3Object.eth.getAccounts();
    // const gasPrice = await web3Object.eth.getGasPrice();
    const owner = accounts[0];
    if (owner == undefined) {
      toast.error("Please connect to metamask and try again!");
    }
    console.log("owner", owner);

    const contract = await callContract();

    const _toList = data?.data?.userAddresses;
    const _token = data?.data?.tokenAddress;
    const _amounts = data?.data?.amounts;
    console.log("MINTING CONTRACT METHOD DATA", _toList, _amounts, _token);
    const gasEstimation = await contract.methods
      .batchMintTokens(_toList, _amounts, _token)
      .estimateGas({ from: owner });

    const result = await contract.methods
      .batchMintTokens(_toList, _amounts, _token)
      .send({
        from: owner,
        gas: gasEstimation,
      });
    console.log("Result of Mint token for a user:", result);
    if (result.events) {
      console.log("Inside events");
      const { events } = result;
      console.log("EVENTS", events);
      const mintedEvent = events.BatchMint;

      if (mintedEvent) {
        console.log("Inside event deployed");

        const walletAddresses = mintedEvent.returnValues.to;
        const mintedAmounts = mintedEvent.returnValues.amount;
        const txHash = mintedEvent.transactionHash;
        console.log(
          "Minted token amount, userAddress :",
          mintedAmounts,
          walletAddresses,
          txHash
        );
        return { mintedAmounts, walletAddresses, txHash }; // Return the valuesl
      } else {
        toast.error("tokenMint event not found in transaction receipt");
        console.error("tokenMint event not found in transaction receipt");
        return null;
      }
    } else {
      console.error("Events property not found in transaction receipt");
      return null;
    }
  } catch (error) {
    if (
      error?.message?.includes("execution reverted: Compliance not followed")
    ) {
      toast.error("Compliance not followed!."); // Display specific error message
    } else if (
      error?.message?.includes("execution reverted: Identity is not verified.")
    ) {
      toast.error("Identity not verified!"); // Display specific error message
    } else if (
      error?.message?.includes(
        "execution reverted: AgentRole: caller does not have the Agent role"
      )
    ) {
      toast.error("User do not have the Agent role!."); // Display specific error message
    } else {
      toast.error(error.message); // Default error message
    }
    console.error("Error while minting tokens:", error.message);
  }
};

export const getPastEventLogsForTokenMint = async () => {
  const web3Object = await callWeb3();
  // const accounts = await web3Object.eth.getAccounts();
  // const gasPrice = await web3Object.eth.getGasPrice();
  // const owner = accounts[0];
  // if (owner == undefined) {
  //   toast.error("Please connect to metamask and try again!");
  // }
  // console.log("owner", owner);

  const contract = await callContract();
  const latestBlock = await web3Object.eth.getBlockNumber();
  console.log("latestBlock", latestBlock);
  const fromBlock = latestBlock - 50000;
  const options = {
    fromBlock: fromBlock, // Start block (optional)
    toBlock: latestBlock, // End block (optional)
    filter: {
      // Event filter criteria (optional)
      // eventName: value,
      // Additional filter criteria...
    },
  };
  // Retrieve past events
  const events = await contract.getPastEvents("tokenMint", options);
  console.log(events);
};

export const getPastEventLogsForBatchMint = async () => {
  const web3Object = await callWeb3();

  const contract = await callContract();
  const latestBlock = await web3Object.eth.getBlockNumber();
  console.log("latestBlock", latestBlock);
  const fromBlock = latestBlock - 50000;
  const options = {
    fromBlock: fromBlock, // Start block (optional)
    toBlock: latestBlock, // End block (optional)
    filter: {
      // Event filter criteria (optional)
      // eventName: value,
      // Additional filter criteria...
    },
  };
  // Retrieve past events
  const events = await contract.getPastEvents("BatchMint", options);
  console.log(events);
};
