import { Breadcrumb, Col, Divider, Row, QRCode, Space, Image } from "antd";
import React, { useEffect, useState } from "react";
import "./AdminControls.scss";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import InputCustom from "../../Ui/input/InputCustom";
import Loader from "../../../Loader/Loader";
import { toast } from "../../Ui/Toast/Toast";
import { changePassword } from "../../../redux/_actions";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import ModalCommon from "../ModalCommon/ModalCommon";
import TriskelLogo from "../../Assets/AdminImages/triskel negro.png";
import { getData } from "../../../Services/Utils";
import * as Constants from "../../../constants/constants";

function AdminControls() {
  const login = useSelector((state) => state?.LoginReducer?.userData);

  const initilaObj = {
    password: "",
    oldPassword: "",
    confirmPassword: "",
    error: {
      password: "",
      oldPassword: "",
      confirmPassword: "",
    },
  };

  const dispatch = useDispatch();
  const [allState, setAllState] = useState(initilaObj);
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [code_2fa, setCode_2fa] = useState("");
  const [authStatus, setAuthStatus] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [keyName, setKeyName] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [showText, setShowText] = useState(false);

  // useEffect(() => {
  //   auth_status();
  // }, [])
  /************************************** check2FA_status ****************************************************/
  // const auth_status = () => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     dispatch(check2FaStatus({})).then(res => {
  //       setAuthStatus(res.toString())
  //       res == 0 ? getSecretKey() : setLoading(false)
  //     }).catch(err => {
  //       setLoading(false)
  //     })
  //   }, 100);
  // }
  /************************************** SubmitPress ****************************************************/
  // const getSecretKey = () => {
  //   dispatch(secretKey_2FA({}))
  //     .then((res) => {
  //       setSecretKey(res.google_secret_key);
  //       setKeyName(res.key_name);
  //       setQrCode(res.qr_code);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // };
  /************************************** SubmitPress ****************************************************/
  const onPress2FA = () => {
    setPassword("");
    setCode_2fa("");
    setIsModalOpen(true);
  };
  /************************************** SubmitPress ****************************************************/
  const SubmitPress = () => {
    let isValid = handleValidaton({
      password: allState.password,
      oldPassword: allState.oldPassword,
      confirmPassword: allState.confirmPassword,
    });
    if (isValid) {
      changePass();
    }
  };
  /************************************** changePass ****************************************************/
  const changePass = () => {
    setLoading(true);
    const data = {
      old_password: allState.oldPassword,
      new_password: allState.password,
    };
    setTimeout(() => {
      dispatch(changePassword({ data }))
        .then((res) => {
          setLoading(false);
          Swal.fire({
            text: "Password Changed Successfully!",
            icon: "success",
            // confirmButtonColor: "#7D80DF",
            confirmButtonColor: "#d3d3d3",
            confirmButtonText: "Ok",
          });
          resetState(initilaObj);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    }, 100);
  };
  /**************************************handleCancel ****************************************************/
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  /**************************************btnClick ****************************************************/
  const btnClick = (type) => {
    if (password.trim().length == 0 || code_2fa.trim().length == 0) {
      return toast.error("All fields are mandatory");
    } else if (code_2fa.trim().length < 6) {
      return toast.error("Enter valid 2FA Code");
    } else if (!Constants.NUMBER_REGEX.test(code_2fa)) {
      return toast.error("Enter valid 2FA Code");
    } else {
      // enable_disabale2FA(type);
    }
  };
  const handleValidaton = (data) => {
    var error = allState?.error;
    var isValidate = true;
    for (let [key, value] of Object.entries(data)) {
      switch (key) {
        case "password":
          if (value === undefined || value?.length === 0) {
            error[key] = "Password Field Is Required";
            isValidate = false;
          } else if (value.trim().length < 8) {
            error[key] = "Password too short!";
            isValidate = false;
          } else if (value === allState.oldPassword) {
            error[key] = "Old password and new password cannot be same!";
            isValidate = false;
          } else if (!Constants.PASS_REGEX.test(value)) {
            error[key] =
              "New password must include minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "confirmPassword":
          if (value === undefined || value?.length === 0) {
            error[key] = "Confirm Password Field is Required";
            isValidate = false;
          } else if (value.trim().length < 8) {
            error[key] = "Password too short!";
            isValidate = false;
          } else if (value !== allState.password) {
            error[key] = "Passwords didn't match!";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "oldPassword":
          if (value === null || value?.length === 0) {
            error[key] = "Old Password Field is Required";
            isValidate = false;
          } else if (value.trim().length < 8) {
            error[key] = "Password too short!";
            isValidate = false;
          } else {
            error[key] = "";
          }
        default:
      }
    }
    setAllState({ ...allState, error: { ...error } });
    return isValidate;
  };
  const resetState = () => {
    setAllState(initilaObj);
  };
  const setValue = (event) => {
    if (event.target.value !== "") {
      var error = allState?.error;
      error[event.target.name] = "";
      setAllState({ ...allState, error: { ...error } });
    }
    setAllState({ ...allState, [event.target.name]: event.target.value });
  };
  /************************************** enable_disabale2FA()****************************************************/
  // const enable_disabale2FA = (type) => {
  //   const data = {
  //     password: password,
  //     action: type == "Enabled" ? 1 : 0,
  //     token: code_2fa,
  //   };
  //   setLoading(true);
  //   setTimeout(() => {
  //     dispatch(enableDisable2Fa({ data }))
  //       .then((res) => {
  //         setLoading(false);
  //         setIsModalOpen(false);
  //         Swal.fire({
  //           text: `2FA ${type} Successfully!`,
  //           icon: "success",
  //           // confirmButtonColor: "#7D80DF",
  //           confirmButtonColor: "#d3d3d3",
  //           confirmButtonText: "Ok",
  //         });
  //         // auth_status();
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         toast.error(err);
  //       });
  //   }, 100);
  // };
  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Admin Controls</Breadcrumb.Item>
        </Breadcrumb>
        {/* <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span> */}
        <span>
          <b>Assigned Role : </b>
          {"Agent"}
        </span>
      </div>
      <div className="adminControls commonPadding">
        <div className="adminControls_betterSecurity">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 20,
            }}
          >
            {/* <ButtonCustom
              onClick={() => onPress2FA()}
              style={{ marginTop: 0, width: "auto" }}
              label={authStatus == 1 ? "Disable 2FA" : "Enable 2FA"}
              yellowBtn
            /> */}
          </div>
        </div>
        {/* ----------------------------------------------------------- */}
        <div className="annoucements_block">
          <h2 className="fontFamilyText">Change Password</h2>
          <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
            {/* ----------------------------------------------------------- */}
            <Col xs={24} md={8}>
              <InputCustom
                label="Enter Old Password"
                passwordInput
                inputCustum
                eyeicon
                placeholder="Enter Old Password"
                name="oldPassword"
                onChange={(e) => setValue(e)}
                value={allState.oldPassword}
              />
              <p className="error">{allState.error.oldPassword}</p>
            </Col>
            {/* ----------------------------------------------------------- */}
            <Col xs={24} md={8}>
              <InputCustom
                label="Enter New Password"
                passwordInput
                inputCustum
                eyeicon
                placeholder="Enter New Password"
                name="password"
                onChange={(e) => {
                  setValue(e);
                  setShowText(false);
                }}
                value={allState.password}
              />
              <p className="error">{allState.error.password}</p>
            </Col>
            {/* ----------------------------------------------------------- */}
            <Col xs={24} md={8}>
              <InputCustom
                label="Confirm New Password"
                passwordInput
                inputCustum
                eyeicon
                placeholder="Confirm New Password"
                name="confirmPassword"
                onChange={(e) => setValue(e)}
                value={allState.confirmPassword}
              />
              <p className="error">{allState.error.confirmPassword}</p>
            </Col>
            {/* ----------------------------------------------------------- */}
          </Row>
          {showText && (
            <Col style={{ marginTop: 15 }}>
              <span style={{ color: "red" }} className="fontFamilyText ">
                New password must include minimum eight characters, at least one
                uppercase letter, one lowercase letter, one number and one
                special character
              </span>
            </Col>
          )}
          <div className="annoucements_block_submitBtn">
            <ButtonCustom
              onClick={() => SubmitPress()}
              label="Submit"
              yellowBtn
            />
          </div>
          {/* ----------------------------------------------------------- */}
          <ModalCommon
            headTittle={authStatus == 1 ? "Disable 2FA" : "Enable 2FA"}
            isModalVisible={isModalOpen}
            handleCancel={handleCancel}
          >
            <div className="modalApp">
              {authStatus != 1 && (
                <Space direction="vertical" align="center">
                  <Image
                    height={250}
                    src={qrCode}
                    alt=""
                    preview={false}
                    style={{ borderRadius: 20, display: "flex" }}
                  />
                </Space>
              )}
              <label>
                <b>Identifier:</b> {keyName}
              </label>
              <InputCustom
                placeholder={"Enter Password"}
                passwordInput
                label="Password"
                eyeicon
                inputCustum
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <InputCustom
                placeholder={"Enter 2FA Code"}
                maxLength={6}
                basicInput
                label="2FA Code"
                inputCustum
                value={code_2fa}
                onChange={(e) => setCode_2fa(e.target.value)}
              />
              <div className="addnewSec">
                <ButtonCustom
                  label={authStatus == 1 ? "Disable 2FA" : "Enable 2FA"}
                  yellowBtn
                  onClick={() => {
                    btnClick(authStatus == 1 ? "Disabled" : "Enabled");
                  }}
                />
              </div>
            </div>
          </ModalCommon>
          {/* ----------------------------------------------------------- */}
        </div>
        {/* ----------------------------------------------------------- */}
      </div>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default AdminControls;
